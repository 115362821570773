#top-navigation {
    [data-utility-index="2"] {
        span {
            &:hover {
                cursor: pointer !important;
            }
        }
        a {
            &:hover {
                color: #d1d5db !important;
                cursor: pointer !important;
            }
        }
        span[class*="awsui_utility-link-icon_"] {
            margin: 0px;
            padding-left: 4px;
        }
    }
}